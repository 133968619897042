
<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Progression"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-data-table
              :items="données"
              :headers="headers"
              data-cy="lb-traces"
            >
              <template v-slot:item.date="{ item }">
                <span>{{ new Date(item.date).toLocaleString() }}</span>
              </template>
              <template v-slot:item.nomNiveau="{ item }">
                <span>{{ convertirTexteI18n(item.nomNiveau, 'fr') }}</span>
              </template>
              <template v-slot:item.réussite="{ item }">
                <span>{{ item.réussite? "Réussite" : "Échec" }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'

  export default {
    data: () => ({
      données: [],
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'Identifiant usager', value: 'nomUsager' },
        { text: 'Niveau', value: 'nomNiveau' },
        { text: 'Scénario', value: 'nomScénario' },
        { text: 'Consultation fiches', value: 'noteFiche' },
        { text: 'Prise de decision (action)', value: 'noteAction' },
        { text: 'Pourcentage', value: 'pourcentageRéussite' },
        { text: 'Statut', value: 'réussite' },
      ],
    }),
    mounted () {
      this.charger()
    },
    methods: {
      charger () {
        restApiService.get('/api/dossier-progressions/historique-progression-par-scenario').then((result) => {
          this.données = result.data
        })
      },
      convertirTexteI18n (i18ntexte, langage) {
        if (!i18ntexte) {
          i18ntexte = ''
        }
        if (i18ntexte.startsWith('<t>')) {
          var domParser = new DOMParser()
          const dom = domParser.parseFromString(i18ntexte, 'application/xml')
          if (langage === 'fr') {
            return dom.documentElement.getElementsByTagName('fr')[0].textContent
          } else {
            return dom.documentElement.getElementsByTagName('en')[0].textContent
          }
        } else {
          return i18ntexte
        }
      },
    },
  }
</script>
<style scoped>
.padding15{
  padding: 15px;
}
</style>
